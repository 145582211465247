import { useParams } from "react-router-dom";
import { findMainCourse } from "../../data";

const MainCourseProcedure = () => {
  let params = useParams();
  const id = Number(params.mainCourseId);
  const mainCourse = findMainCourse(id);

  return (
    <div>
      <img
        src={mainCourse.image.src}
        alt={mainCourse.image.alt}
        className="media_style"
      />
      <h4 className="focus_title">{mainCourse.name}</h4>

      <div className="col-12 content">{mainCourse.dateOfTheRecipe}</div>
      <h3 className="col-12 slogan">La nutrizionista consiglia</h3>
      <p className="col-12 content">{mainCourse.description}</p>
      <h3 className="col-12 slogan">Ingredienti</h3>
      <p className="col-12 content">{mainCourse.ingredients}</p>

      <h3 className="col-12 slogan">Procedimento</h3>
      <p className="col-12 content">{mainCourse.steps}</p>
    </div>
  );
};

export default MainCourseProcedure;
