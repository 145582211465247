import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { getSecondCourse } from "../../data";

export default function SecondCourse() {
  let secondCourse = getSecondCourse();
  return (
    <div>
      <h1 className="second_title mt-3">Secondi e contorni</h1>

      {secondCourse.map((recipe) => (
        <div key={recipe.id}>
          {" "}
          <img
            src={recipe.image.src}
            alt={recipe.image.alt}
            className="media_style"
          />
          <h4 className="focus_title">{recipe.name}</h4>
          <div className="col-12 content mt-3">{recipe.description}</div>
          <div className="link_to_subpage">
            <NavLink
              className="link_to_subpage"
              to={`/Sanaalimentazioneesalute/SecondieContorni/${recipe.id}`}
            >
              Continua a leggere...
            </NavLink>
          </div>
        </div>
      ))}

      <Outlet />
    </div>
  );
}
